import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../axiosInstance";

const SetUsers = () => {
  const navigate = useNavigate();
  const { designation, user } = useParams();

  const [formData, setFormData] = useState({
    Name: "",
    Password: "",
    Privilege: "0",
    designation: "",
    EnrollNumber: "",
  });
  const [requestPending, setRequestPending] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setRequestPending(true);
    axiosInstance
      .post(
        "/users/set",
        {
          designation: designation,
          name: formData.Name,
          password: formData.Password,
          privilege: formData.Privilege,
          enrollNumber: formData.EnrollNumber,
        },
        { timeout: 60000 }
      )
      .then((response) => {
        setRequestPending(false);
        if (response.data.status === "success") {
          navigate(`/devices/users/${designation}`);
        }
        console.log(response.data);
      })
      .catch((error) => {
        setRequestPending(false);
      });
  };

  useEffect(() => {
    if (user) {
      try {
        const jsonString = atob(user);
        const decodedData = JSON.parse(jsonString);
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...decodedData,
        }));
      } catch (error) {
        console.error("Failed to decode user data:", error);
      }
    }
  }, [user]);

  return (
    <div className="p-2 mt-3">
      <h5>Set user</h5>
      <hr />

      <form className="mr-1" onSubmit={formSubmit}>
        <input
          type="hidden"
          name="enrollNumber"
          value={formData.EnrollNumber}
          onChange={handleInputChange}
        />
        <div className="mb-2 form-group row align-items-center">
          <label className="col-3">Name or User id:</label>
          <input
            onChange={handleInputChange}
            name="Name"
            className="form-control col"
            value={formData.Name || ""}
            placeholder="name"
            required
          />
        </div>

        <div className="mb-2 form-group row align-items-center">
          <label className="col-3">Password:</label>
          <input
            onChange={handleInputChange}
            name="Password"
            className="form-control col"
            value={formData.Password || ""}
            placeholder="Enter password"
          />
        </div>

        <div className="mb-3 form-group row align-items-center">
          <label className="col-3">Privilege:</label>
          <select
            className="form-control col"
            name="Privilege"
            value={formData.Privilege || "0"}
            onChange={handleInputChange}
          >
            <option value="0">User</option>
            <option value="3">Admin</option>
          </select>
        </div>

        {requestPending ? (
          <button className="mt-auto ml-auto btn btn-primary d-block" disabled>
            Please wait...
          </button>
        ) : (
          <button className="mt-auto ml-auto btn btn-primary d-block shadow-sm">
            submit
          </button>
        )}
      </form>
    </div>
  );
};

export default SetUsers;
