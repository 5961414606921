import { useState } from "react";
import axiosInstance from "../axiosInstance";
import { useNavigate } from "react-router-dom";

const SetDevices = () => {
  const navigate = useNavigate();
  const defaultFormDataValues = { designation: "", ip: "" };
  const [formData, setFormData] = useState(defaultFormDataValues);
  const [requestPending, setRequestPending] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const submitForm = (e) => {
    e.preventDefault();
    setRequestPending(true);
    axiosInstance
      .post("/devices/set", formData, { timeout: 5000 })
      .then((response) => {
        setRequestPending(false);
        if (response.data.status === "success") {
          navigate("/devices");
        }
      })
      .catch((error) => {
        setRequestPending(false);
      });
  };
  return (
    <div className="p-2 mt-3">
      <h5>Set Device</h5>
      <hr />

      <form className="mt-3 mr-1" onSubmit={submitForm}>
        <div className="form-group row mb-3 align-items-center">
          <label className="col-3">Device designation:</label>
          <input
            className="form-control col"
            name="designation"
            type="text"
            onChange={handleInputChange}
            value={formData.designation}
            placeholder="Enter value"
            required
          ></input>
        </div>

        <div className="form-group row mb-3 align-items-center">
          <label className="col-3">Device ip address:</label>
          <input
            className="form-control col"
            name="ip"
            type="text"
            onChange={handleInputChange}
            value={formData.ip}
            placeholder="Enter value"
            required
          ></input>
        </div>

        {requestPending && (
          <button className="mt-auto ml-auto btn btn-primary d-block" disabled>
            Please wait...
          </button>
        )}

        {!requestPending && (
          <button className="mt-auto ml-auto btn btn-primary d-block shadow">
            submit
          </button>
        )}
      </form>
    </div>
  );
};

export default SetDevices;
