import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "./axiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faCogs,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import TableStyle1 from "./TableStyle";
import { Modal } from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { update } from "firebase/database";

const Users = () => {
  const { designation } = useParams();
  const navigate = useNavigate();
  const sweetAlert = withReactContent(Swal);
  const formDataValues = { Name: "", Password: "", Privilege: "0" };
  const [users, setUsers] = useState([]);
  const [formDataGroup, setFormDataGroup] = useState({ group: "" });
  const [showModalGroup, setShowModalGroup] = useState(false);
  const [rowSelected, setRowSelected] = useState([]);
  const [toggleSelection, setToggleSelection] = useState(false);
  const [groups, setGroups] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const result = users.filter((row) => {
      return (
        row.Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (row.Group &&
          row.Group.toLowerCase().includes(searchQuery.toLowerCase())) ||
        row.EnrollNumber.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setSearchResult(result);
  }, [searchQuery]);

  const handleCloseModalGroup = () => setShowModalGroup(false);
  const handleShowModalGroup = () => {
    setShowModalGroup(true);
  };

  const handleGroupSelect = (e) => {
    const { name, value } = e.target;
    setFormDataGroup({ ...formDataGroup, [name]: value });
  };

  const formSubmitGroup = (e) => {
    e.preventDefault();
    axiosInstance
      .post("/groups/assign", {
        group: formDataGroup.group,
        selected: rowSelected.map((item) => item.id),
      })
      .then((response) => {
        setToggleSelection(!toggleSelection);
        fetchUsers();
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchUsers();

    axiosInstance
      .get("/groups/get", { timeout: 10000 })
      .then((response) => {
        setGroups(response.data);
      })
      .catch((error) => {});
  }, [designation]);

  const updateUser = (user) => {
    const data = {
      designation: designation,
      Name: user.Name,
      Privilege: user.Privilege,
      Password: user.Password,
      EnrollNumber: user.EnrollNumber,
    };

    const encoded = btoa(JSON.stringify(data));

    navigate(`/devices/set/${designation}/${encoded}`);
  };

  const fetchUsers = () => {
    axiosInstance
      .get(
        "/users/get",
        { params: { designation: designation } },
        { timeout: 10000 }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setUsers(response.data.data);
        }
      })
      .catch((error) => {});
  };

  const handleSelectedRow = ({ selectedRows }) => {
    setRowSelected(selectedRows);
  };

  const deleteUsers = () => {
    axiosInstance
      .post("/users/delete", {
        designation: designation,
        enrollNumbers: rowSelected.map((item) => item.EnrollNumber),
      })
      .then((response) => {
        setToggleSelection(!toggleSelection);
        fetchUsers();
      })
      .catch((error) => {});
  };

  const contextAction = (
    <div className="d-flex">
      <button className="btn btn-light" onClick={handleShowModalGroup}>
        Assign group
      </button>
      <button className="btn" type="button" onClick={() => deleteUsers()}>
        <FontAwesomeIcon icon={faTrash} />
      </button>
    </div>
  );

  const columns = [
    {
      name: "Name",
      cell: (row) => <span className="row-title">{row.Name}</span>,
    },
    { name: "EnrollNumber", cell: (row) => <span>{row.EnrollNumber}</span> },
    { name: "Group", cell: (row) => <span>{row.Group}</span> },
    {
      name: "Action",
      cell: (row) => (
        <div className="dropdown">
          <button
            className="btn"
            role="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <FontAwesomeIcon className="icon-light" icon={faCogs} />
          </button>
          <div className="dropdown-menu dropdown-menu-right ">
            <button
              type="button"
              class="dropdown-item"
              onClick={() => updateUser(row)}
            >
              Update user
            </button>

            <Link
              class="dropdown-item"
              to={`/devices/${designation}/user/${row.Name}/logs/${row.EnrollNumber}`}
            >
              View attendance logs
            </Link>
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="card shadow-none d-flex flex-row align-items-center">
        <div className="input-group border-0 mr-2">
          <div className="input-group-prepend border-0">
            <span className="input-group-text border-0">
              <i className="material-icons">search</i>
            </span>
          </div>
          <input
            type="text"
            className="form-control border-0"
            placeholder="Search"
            aria-label="Search"
            onChange={handleSearch}
          />
        </div>

        <Link
          type="button"
          className="ml-auto btn btn-round mr-1 shadow"
          to={`/devices/set/${designation}`}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Link>
      </div>

      <div className="card shadow-none mt-4 box-shadow">
        <DataTable
          title={`Enrolled users ${designation}`}
          columns={columns}
          data={searchQuery.length > 1 ? searchResult : users}
          customStyles={TableStyle1}
          selectableRows
          pagination
          paginationPerPage={5}
          onSelectedRowsChange={handleSelectedRow}
          clearSelectedRows={toggleSelection}
          contextActions={contextAction}
        />
      </div>

      <Modal
        show={showModalGroup}
        onHide={handleCloseModalGroup}
        className="modal-grow"
      >
        <form onSubmit={formSubmitGroup}>
          <Modal.Header className="bg-white">
            <h6>Assign group</h6>
            <button
              type="button"
              className="btn btn-sm"
              onClick={() => handleCloseModalGroup}
            >
              <FontAwesomeIcon className="icon-lg icon-light" icon={faClose} />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>Group:</label>
              <select
                name="group"
                className="form-control"
                onChange={handleGroupSelect}
              >
                <option value={""}>Select</option>
                {groups.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-white border-top">
            <button
              type="submit"
              className="btn ml-auto btn-sm  btn-primary"
              onClick={handleCloseModalGroup}
            >
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default Users;
