import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../axiosInstance";

const SetShifts = () => {
  const { shift } = useParams();
  const formDataValues = {
    id: "",
    Name: "",
    start: "",
    startVariance: "",
    end: "",
    endVariance: "",
  };
  const [requestPending, setRequestPending] = useState(false);
  const [formData, setFormData] = useState(formDataValues);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setRequestPending(true);
    axiosInstance
      .post(
        "/shifts/set",
        {
          id: formData.id,
          name: formData.Name,
          start: formData.start,
          startVariance: formData.startVariance,
          end: formData.end,
          endVariance: formData.endVariance,
        },
        { timeout: 60000 }
      )
      .then((response) => {
        setRequestPending(false);
        if (response.data.status === "success") {
          navigate("/shifts");
        }
      })
      .catch((error) => {
        setRequestPending(false);
      });
  };

  useEffect(() => {
    if (shift) {
      try {
        const jsonString = atob(shift);
        const decodedData = JSON.parse(jsonString);
        setFormData(decodedData);
      } catch (error) {
        console.error("Failed to decode shift data:", error);
      }
    }
  }, [shift]);
  return (
    <div className="p-2 mt-3">
      <h5>Set Shift</h5>
      <hr />

      <form className="mr-1" onSubmit={formSubmit}>
        <input
          type="hidden"
          name="id"
          value={formData.id}
          onChange={handleInputChange}
        />
        <div className="mb-2 form-group row align-items-center">
          <label className="col-3">Name:</label>
          <input
            onChange={handleInputChange}
            name="Name"
            className="form-control col"
            value={formData.Name}
            placeholder="Shift 1"
            required
          ></input>
        </div>

        <div className="mb-2 form-group row align-items-center">
          <label className="col-3">Start Time:</label>
          <input
            onChange={handleInputChange}
            name="start"
            className="form-control col"
            value={formData.start}
            placeholder="8:00"
            required
          ></input>
        </div>

        <div className="mb-2 form-group row align-items-center">
          <label className="col-3">Max ClockIn Variance:</label>
          <input
            onChange={handleInputChange}
            name="startVariance"
            className="form-control col"
            value={formData.startVariance}
            required
            placeholder="60"
          ></input>
        </div>

        <div className="mb-2 form-group row align-items-center">
          <label className="col-3">End Time:</label>
          <input
            onChange={handleInputChange}
            name="end"
            className="form-control col"
            value={formData.end}
            placeholder="5:00"
            required
          ></input>
        </div>

        <div className="mb-2 form-group row align-items-center">
          <label className="col-3">Max ClockOut Variance:</label>
          <input
            onChange={handleInputChange}
            name="endVariance"
            className="form-control col"
            value={formData.endVariance}
            placeholder="60"
            required
          ></input>
        </div>

        {requestPending && (
          <button className="mt-auto ml-auto btn btn-primary d-block" disabled>
            Please wait...
          </button>
        )}

        {!requestPending && (
          <button className="mt-auto ml-auto btn btn-primary d-block shadow-sm">
            submit
          </button>
        )}
      </form>
    </div>
  );
};

export default SetShifts;
