import { useState } from "react";
import axiosInstance from "./axiosInstance";

const Settings = () => {
  const [requestPending, setRequestPending] = useState(false);
  const [deleteUserBoth, setDeleteUserBoth] = useState(false);

  const handleCheckboxChange = () => {
    setDeleteUserBoth(!deleteUserBoth);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setRequestPending(true);
    axiosInstance
      .post("/settings/set", { delete: deleteUserBoth }, { timeout: 10000 })
      .then((response) => {
        setRequestPending(false);
      })
      .catch((error) => {
        setRequestPending(false);
      });
  };

  return (
    <div className="content-wrapper">
      <div className="side-panel px-lg-1 p-1 pt-2">
        <ul className="nav nav-tabs flex-column bg-white" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="profile-tab"
              data-toggle="tab"
              href="#itinerary"
              role="tab"
              aria-controls="itinerary"
              aria-selected="true"
            >
              Device functions
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-content w-100 p-lg-3 p-2">
        <form onSubmit={handleSubmit}>
          <div className="custom-control custom-checkbox custom-checkbox-switch mb-3">
            <input
              type="checkbox"
              className="custom-control-input"
              id="notify-1"
              checked={deleteUserBoth}
              onChange={handleCheckboxChange}
            />
            <label className="custom-control-label" htmlFor="notify-1">
              Delete user both on database and device
            </label>
          </div>
          <div className="d-flex justify-content-end">
            {requestPending && (
              <button type="submit" className="btn btn-primary" disabled>
                Please wait...
              </button>
            )}

            {!requestPending && (
              <button type="submit" className="btn btn-primary shadow">
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Settings;
