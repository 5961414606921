import * as XLSX from "xlsx";

const exportToExcel = (jsonData, fileName, title) => {
  const ws = XLSX.utils.json_to_sheet([]);

  // Add title
  XLSX.utils.sheet_add_aoa(ws, [[title]], { origin: "A1" });

  // Merge cells for the title row
  ws["!merges"] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }, // Adjust the range based on the number of columns
  ];

  // Set the style for the title row
  const titleCell = ws["A1"];
  if (!titleCell.s) titleCell.s = {};
  titleCell.s.font = { bold: true };
  titleCell.s.alignment = { horizontal: "center" };

  // Add data below the title
  XLSX.utils.sheet_add_json(ws, jsonData, { origin: "A2", skipHeader: false });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export default exportToExcel;
