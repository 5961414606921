import {
  faDollar,
  faHome,
  faList,
  faNoteSticky,
  faPerson,
  faStickyNote,
  faSync,
  faTasks,
  faUser,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { faTable } from "@fortawesome/free-solid-svg-icons/faTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink } from "react-router-dom";
const logo = process.env.PUBLIC_URL + "/logo_gwel.png";

const Sidebar = () => {
  const navigation = [
    {
      label: "Devices",
      navigation: "/devices",
      icon: <span className="material-symbols-sharp">credit_card</span>,
    },

    {
      label: "Shifts",
      navigation: "/shifts",
      icon: <span className="material-symbols-sharp">tabs</span>,
    },

    {
      label: "Groups",
      navigation: "/groups",
      icon: <span className="material-symbols-sharp">tab</span>,
    },

    {
      label: "Reports",
      navigation: "/reports",
      icon: <span className="material-symbols-sharp">dialogs</span>,
    },
  ];
  return (
    <div className="navbar navbar-expand-lg bg-dark navbar-dark sticky-top p-0">
      <div className="d-none d-lg-block logo mb-0 sidebar-user w-100">
        <img alt="Image" src={logo} className="avatar" />
        <span className="text-white text-bold">GWEL</span>
        <hr></hr>
      </div>

      <div className="d-flex align-items-center justify-content-between w-100">
        <button
          className="navbar-toggler border-0"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-collapse"
          aria-controls="navbar-collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="d-block d-lg-none ml-2">
          <div className="dropdown">
            <a
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img alt="Image" src={logo} className="avatar" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <a href="utility-account-settings.html" className="dropdown-item">
                Account Settings
              </a>
              <button className="dropdown-item">Log Out</button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="collapse navbar-collapse flex-column"
        id="navbar-collapse"
      >
        <ul class="navbar-nav d-lg-block  mt-sm-0 accordion" id="accordion">
          {navigation.map((menu, index) => (
            <li className="nav-item" key={index}>
              <NavLink to={menu.navigation} className="nav-link">
                <span className="sidebar-icon">{menu.icon}</span>
                <span>{menu.label}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
