// utils/axiosInstance.js
import axios from "axios";

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: "https://attendance.wellsoil.co.ke/api",
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // For example, you could add an authorization token here:
    // config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lies within the range of 2xx causes this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx causes this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
