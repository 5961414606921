import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import axiosInstance from "../axiosInstance";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import TableStyle1 from "../TableStyle";
import jsPDF from "jspdf";
import "jspdf-autotable";
import exportToExcel from "../xlsx";

const Logs = () => {
  const { designation } = useParams();
  const [requestPending, setRequestPending] = useState(false);
  const [date1, setDate1] = useState(moment().format("YYYY-MM-DD"));
  const [date2, setDate2] = useState(moment().format("YYYY-MM-DD"));
  const [logs, setLogs] = useState([]);
  const [groups, setGroups] = useState([]);
  const [formData, setFormData] = useState({
    group: "",
    weekend: false,
  });

  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const result = logs.filter((row) => {
      return (
        row.Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.EnrollNumber.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setSearchResult(result);
  }, [searchQuery]);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setRequestPending(true);
    axiosInstance
      .get("/devices/logs/get", {
        params: {
          designation: designation,
          group: formData.group,
          weekend: formData.weekend,
          date1: date1,
          date2: date2,
        },
      })
      .then((response) => {
        setRequestPending(false);
        if (response.data.status === "success") {
          setLogs(response.data.attendanceLogs);
        }
      })
      .catch((error) => {
        setRequestPending(false);
      });
  };

  const columns = [
    { name: "Date", cell: (row) => <span>{row.Date}</span> },
    {
      name: "Name",
      cell: (row) => <span className="row-title">{row.Name}</span>,
    },
    { name: "Enroll Number", cell: (row) => <span>{row.EnrollNumber}</span> },
    {
      name: "ClockIn",
      cell: (row) => (
        <span>
          {row.ClockIn ? moment(row.ClockIn * 1000).format("h:mm:ss a") : "-"}
        </span>
      ),
    },
    {
      name: "ClockOut",
      cell: (row) => (
        <span>
          {row.ClockOut ? moment(row.ClockOut * 1000).format("h:mm:ss a") : "-"}
        </span>
      ),
    },
    {
      name: "Absent",
      cell: (row) => (
        <span>{!row.ClockIn && !row.ClockOut ? "Yes" : "No"}</span>
      ),
    },
    {
      name: "WorkTime",
      cell: (row) => {
        if (row.ClockIn && row.ClockOut) {
          const start = moment(row.ClockIn * 1000);
          const end = moment(row.ClockOut * 1000);
          const duration = moment.duration(end.diff(start));
          const hours = Math.floor(duration.asHours());
          const minutes = duration.minutes();
          const seconds = duration.seconds();
          return <span>{`${hours}h ${minutes}m ${seconds}s`}</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
  ];

  // Function to export PDF
  const exportPDF = () => {
    const doc = new jsPDF("landscape");

    doc.text(
      `Cumulative Attendance Report ${designation}; ${date1}-${date2}`,
      10,
      10
    );

    doc.autoTable({
      headStyles: { fillColor: [71, 160, 72] },
      head: [
        [
          "Date",
          "Name",
          "Enroll Number",
          "ClockIn",
          "ClockOut",
          "Absent",
          "WorkTime",
        ],
      ],
      body: logs.map((row) => [
        row.Date,
        row.Name,
        row.EnrollNumber,
        row.ClockIn ? moment(row.ClockIn * 1000).format("h:mm:ss a") : "-",
        row.ClockOut ? moment(row.ClockOut * 1000).format("h:mm:ss a") : "-",
        !row.ClockIn && !row.ClockOut ? "Yes" : "No",
        row.ClockIn && row.ClockOut
          ? (() => {
              const start = moment(row.ClockIn * 1000);
              const end = moment(row.ClockOut * 1000);
              const duration = moment.duration(end.diff(start));
              const hours = Math.floor(duration.asHours());
              const minutes = duration.minutes();
              const seconds = duration.seconds();
              return `${hours}h ${minutes}m ${seconds}s`;
            })()
          : "-",
      ]),
    });

    doc.save(`Daily attendance report ${designation}-${date1}-${date2}.pdf`);
  };

  const exportExcel = () => {
    const excelData = logs.map((row) => ({
      Date: row.Date,
      Name: row.Name,
      EnrollNumber: row.EnrollNumber,
      ClockIn: row.ClockIn
        ? moment(row.ClockIn * 1000).format("h:mm:ss a")
        : "-",
      ClockOut: row.ClockOut
        ? moment(row.ClockOut * 1000).format("h:mm:ss a")
        : "-",
      Absent: !row.ClockIn && !row.ClockOut ? "Yes" : "No",
      WorkTime:
        row.ClockIn && row.ClockOut
          ? (() => {
              const start = moment(row.ClockIn * 1000);
              const end = moment(row.ClockOut * 1000);
              const duration = moment.duration(end.diff(start));
              const hours = Math.floor(duration.asHours());
              const minutes = duration.minutes();
              const seconds = duration.seconds();
              return `${hours}h ${minutes}m ${seconds}s`;
            })()
          : "-",
    }));

    exportToExcel(
      excelData,
      `Daily attendance report ${designation}-${date1}-${date2}`,
      `Daily attendance report ${designation}; ${date1}-${date2}`
    );
  };

  useEffect(() => {
    axiosInstance.get("/groups/get", { timeout: 10000 }).then((response) => {
      setGroups(response.data);
    });
  }, []);

  return (
    <>
      <div className="card shadow-none d-flex flex-row align-items-center">
        <div className="input-group border-0 mr-2">
          <div className="input-group-prepend border-0">
            <span className="input-group-text border-0">
              <i className="material-icons">search</i>
            </span>
          </div>
          <input
            type="text"
            className="form-control border-0"
            placeholder="Search"
            aria-label="Search"
            onChange={handleSearch}
          />
        </div>

        <div className="dropdown">
          <button
            type="button"
            className="ml-auto btn btn-round mr-1 shadow"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="material-icons">download</span>
          </button>

          <div className="dropdown-menu">
            <button className="dropdown-item" onClick={exportExcel}>
              Export excel
            </button>
            <button className="dropdown-item" onClick={exportPDF}>
              Export pdf
            </button>
          </div>
        </div>
      </div>

      <h6 className="mt-3">Select date range</h6>
      <div className=" card-body card shadow-none">
        <form className="d-flex align-items-center " onSubmit={formSubmit}>
          <div className="d-flex align-items-center">
            <label className="">Start: </label>
            <DatePicker
              showIcon
              selected={moment(date1).toDate()}
              onChange={(date) => setDate1(formatDate(date))}
              dateFormat="yyyy/MM/dd"
              placeholderText="Select date"
              name="date1"
              className="form-control"
              icon={
                <FontAwesomeIcon className="icon-light" icon={faCalendar} />
              }
            />
          </div>

          <div className="d-flex align-items-center mx-2">
            <label className="">End: </label>
            <DatePicker
              showIcon
              selected={moment(date2).toDate()}
              onChange={(date) => setDate2(formatDate(date))}
              dateFormat="yyyy/MM/dd"
              placeholderText="Select date"
              name="date2"
              className="form-control"
              icon={
                <FontAwesomeIcon className="icon-light" icon={faCalendar} />
              }
            />
          </div>

          <div className="d-flex align-items-center">
            <label>Group:</label>
            <select
              name="group"
              className="form-control"
              onChange={handleInputChange}
            >
              <option value={""}>Select</option>
              {groups.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="d-flex align-items-center ml-1">
            <label>Weekend:</label>
            <select
              name="weekend"
              className="form-control"
              onChange={handleInputChange}
            >
              <option value={""}>Select</option>
              <option value={false}>Yes</option>
              <option value={true}>No</option>
            </select>
          </div>

          {requestPending && (
            <div className="ml-auto">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}

          {!requestPending && (
            <button className="btn btn-round ml-auto" type="submit">
              <span className="material-icons">search</span>
            </button>
          )}
        </form>
      </div>

      <div className="card shadow-none mt-4 box-shadow">
        <DataTable
          title={`Attendance logs`}
          customStyles={TableStyle1}
          columns={columns}
          data={searchQuery.length > 1 ? searchResult : logs}
          pagination
          paginationPerPage={5}
        />
      </div>
    </>
  );
};

export default Logs;
