const colors = { default: "#e9ecef", white: "#fff" };
const TableStyle1 = {
  table: {
    backgroundColor: colors.default,
    borderRadius: "0",
  },
  headCells: {
    style: {
      fontSize: "15px",
      color: "#000",
      fontWeight: "500",
      verticalAlign: "bottom",
    },
  },

  header: {},

  headRow: {},

  rows: {},

  cells: {},

  noData: {
    style: {
      display: "none",
    },
  },

  contextMenu: {
    style: {
      backgroundColor: colors.white,
      opacity: "0.9",
      borderRadius: "4px",
    },
  },

  pagination: {
    style: {
      backgroundColor: colors.white,
      border: "0px",
    },
  },
};

export default TableStyle1;
