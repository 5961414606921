import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";

const SetGroups = () => {
  const navigate = useNavigate();
  const formDataValues = {
    id: "",
    Name: "",
  };

  const [formData, setFormData] = useState(formDataValues);
  const [requestPending, setRequestPending] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const formSubmit = (e) => {
    e.preventDefault();
    setRequestPending(true);
    axiosInstance
      .post("/groups/set", formData, { timeout: 60000 })
      .then((response) => {
        setRequestPending(false);
        if (response.data.status === "success") {
          navigate("/groups");
        }
      })
      .catch((error) => {
        setRequestPending(false);
      });
  };
  return (
    <div className="p-2 mt-3">
      <h5>Set group</h5>
      <hr />

      <form className="mr-1" onSubmit={formSubmit}>
        <div className="mb-4 form-group row align-items-center">
          <label className="col-3">Name:</label>
          <input
            onChange={handleInputChange}
            name="Name"
            className="form-control col"
            value={formData.Name}
            placeholder="Group name"
            required
          ></input>
        </div>

        {requestPending && (
          <button className="mt-auto ml-auto btn btn-primary d-block" disabled>
            Please wait...
          </button>
        )}

        {!requestPending && (
          <button className="mt-auto ml-auto btn btn-primary d-block shadow-sm">
            submit
          </button>
        )}
      </form>
    </div>
  );
};

export default SetGroups;
