import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Sidebar from "./sidebar";
import Navbar from "./navbar";
import Overview from "./overview";
import Shortcodes from "./shortcodes";
import Devices from "./devices";
import Users from "./users";
import Logs from "./device/logs";
import UserLogs from "./device/user/logs";
import Shifts from "./shifts";
import Reports from "./reports";
import Groups from "./groups";
import SetDevices from "./set/devices";
import SetShifts from "./set/shifts";
import SetGroups from "./set/groups";
import SetUsers from "./set/users";
import { useEffect, useLayoutEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axiosInstance from "./axiosInstance";
import ManageUsers from "./auth/users";
import SetUser from "./set/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import Settings from "./settings";

function App() {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [requestPending, setRequestPending] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setRequestPending(true);
    setError("");
    axiosInstance
      .post("/auth/login", formData, { timeout: 10000 })
      .then((response) => {
        setRequestPending(false);
        if (response.data.status === "success") {
          setLoggedIn(true);
          Cookies.set("isLoggedIn", true, { expires: 1 / 96 });
          handleCloseModal();
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setRequestPending(false);
      });
  };

  const handleLogout = () => {
    setLoggedIn(false);
    Cookies.remove("isLoggedIn");
    handleShowModal();
  };

  useEffect(() => {
    const isLoggedInCookie = Cookies.get("isLoggedIn");
    if (isLoggedInCookie) {
      setLoggedIn(true);
    } else {
      handleShowModal();
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="layout layout-nav-side">
        <Sidebar />

        <div className="main-container">
          <Navbar loggedIn={loggedIn} onLogout={handleLogout} />
          {loggedIn && (
            <Routes>
              <Route path="/auth/users" element={<ManageUsers />} />
              <Route path="/devices" element={<Devices />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/devices" element={<Devices />} />
              <Route path="/shifts" element={<Shifts />} />
              <Route path="/groups" element={<Groups />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/devices/users/:designation" element={<Users />} />
              <Route
                path="/devices/attendance/logs/:designation"
                element={<Logs />}
              />

              <Route
                path="/devices/:designation/user/:name/logs/:id/"
                element={<UserLogs />}
              />

              <Route path="/devices/set" element={<SetDevices />} />
              <Route path="/shifts/set/:shift" element={<SetShifts />} />
              <Route path="/shifts/set" element={<SetShifts />} />
              <Route path="/groups/set" element={<SetGroups />} />

              <Route path="/devices/set/:designation" element={<SetUsers />} />

              <Route
                path="/devices/set/:designation/:user"
                element={<SetUsers />}
              />

              <Route path="/auth/users/set" element={<SetUser />} />
            </Routes>
          )}
        </div>
      </div>
      <Modal
        backdrop="static"
        keyboard={false}
        show={showModal}
        onHide={handleCloseModal}
        className="modal-grow"
      >
        <form onSubmit={formSubmit}>
          <Modal.Header className="bg-white border-bottom">
            <h6>Login required!</h6>
          </Modal.Header>
          <Modal.Body>
            {error.length > 1 && (
              <div className="alert alert-secondary" role="alert">
                <FontAwesomeIcon /> {error}
              </div>
            )}
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">person</i>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter username"
                aria-label="Username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">lock</i>
                </span>
              </div>
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                aria-label="Password"
                value={formData.password}
                onChange={handleInputChange}
                name="password"
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-white border-top">
            {requestPending && (
              <button
                className="mt-auto ml-auto btn btn-primary d-block"
                disabled
              >
                Please wait...
              </button>
            )}

            {!requestPending && (
              <button className="mt-auto ml-auto btn btn-primary d-block shadow-sm">
                submit
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </BrowserRouter>
  );
}

export default App;
