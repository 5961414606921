import {
  faCheck,
  faClose,
  faCogs,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Canvas from "./canvas";
import axiosInstance from "./axiosInstance";
import DataTable from "react-data-table-component";
import TableStyle1 from "./TableStyle";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const Devices = () => {
  const [devices, setDevices] = useState([]);
  const [rowSelected, setRowSelected] = useState([]);
  const [toggleSelection, setToggleSelection] = useState(false);
  const sweetAlert = withReactContent(Swal);
  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const result = devices.filter((row) => {
      return row.designation.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setSearchResult(result);
  }, [searchQuery]);

  const fetchDevices = () => {
    axiosInstance
      .get("/devices/get", { timeout: 5000 })
      .then((response) => {
        setDevices(response.data);
      })
      .catch((error) => {});
  };

  const getUsers = (device) => {
    sweetAlert.fire({
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      html: (
        <div className="p-5">
          <span className="text-gray-300">...Fetching users from device</span>
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ),
      didOpen: () => {
        //execute request
        axiosInstance
          .post(
            "/devices/users/fetch",
            { ip: device.ip, designation: device.designation },
            { timeout: 20000 }
          )
          .then((response) => {
            sweetAlert.update({
              html:
                response.data.status != "success" ? (
                  <span>{response.data.message}</span>
                ) : (
                  <span className="">
                    {`${response.data.message} Users synced to database`}
                  </span>
                ),
            });
          })
          .catch((error) => {
            sweetAlert.update({
              html: <span>Network error</span>,
            });
          });
      },
    });
  };

  const checkConnection = (device) => {
    sweetAlert.fire({
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      html: (
        <div className="p-5">
          <span className="text-gray-300">...Checking connection</span>
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ),
      didOpen: () => {
        //execute request
        axiosInstance
          .post("/devices/connection", { ip: device.ip }, { timeout: 20000 })
          .then((response) => {
            sweetAlert.update({
              html:
                response.data != "success" ? (
                  <span>{response.data.message}</span>
                ) : (
                  <span className="">{response.data.message}</span>
                ),
            });
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });
  };
  const syncLogs = (device) => {
    sweetAlert.fire({
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      html: (
        <div className="p-5">
          <span className="text-gray-300">
            ...Fetching attendance logs from device.
          </span>
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ),
      didOpen: () => {
        //execute request
        axiosInstance
          .post(
            "/devices/logs/fetch",
            { ip: device.ip, designation: device.designation },
            { timeout: 3000000 }
          )
          .then((response) => {
            sweetAlert.update({
              html:
                response.data.status != "success" ? (
                  <span>{response.data.message}</span>
                ) : (
                  <span className="">
                    {`${response.data.message} Logs synced to database`}
                  </span>
                ),
            });
          })
          .catch((error) => {
            sweetAlert.update({
              html: <span>Network error</span>,
            });
          });
      },
    });
  };

  const deleteDevices = () => {
    axiosInstance
      .post("/devices/delete", rowSelected, { timeout: 10000 })
      .then((response) => {
        if (response.data.status === "success") {
          fetchDevices();
          setToggleSelection(!toggleSelection);
        }
      })
      .catch((error) => {});
  };

  const handleSelectedRow = ({ selectedRows }) => {
    setRowSelected(selectedRows);
  };

  const columns = [
    {
      name: "Designation",
      cell: (row) => <span className="row-title">{row.designation}</span>,
    },
    { name: "Ip address", cell: (row) => <span>{row.ip}</span> },
    { name: "Status", cell: (row) => <span>{row.status}</span> },
    {
      name: "Action",
      cell: (row) => (
        <div className="dropdown">
          <button
            className="btn"
            role="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <FontAwesomeIcon className="icon-light" icon={faCogs} />
          </button>
          <div className="dropdown-menu dropdown-menu-right ">
            <button class="dropdown-item" onClick={() => checkConnection(row)}>
              Check connection
            </button>

            <button class="dropdown-item" onClick={() => getUsers(row)}>
              Get users
            </button>

            <button class="dropdown-item" onClick={() => syncLogs(row)}>
              Sync logs
            </button>

            <Link
              class="dropdown-item"
              to={`/devices/users/${row.designation}`}
            >
              View users on device
            </Link>

            <Link
              class="dropdown-item"
              to={`/devices/attendance/logs/${row.designation}`}
            >
              View attendance logs
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const contextAction = (
    <button className="btn" type="button" onClick={() => deleteDevices()}>
      <FontAwesomeIcon icon={faTrash} />
    </button>
  );

  useEffect(() => {
    fetchDevices();
  }, []);

  return (
    <>
      <div className="card shadow-none d-flex flex-row align-items-center">
        <div className="input-group border-0 mr-2">
          <div className="input-group-prepend border-0">
            <span className="input-group-text border-0">
              <i className="material-icons">search</i>
            </span>
          </div>
          <input
            type="text"
            className="form-control border-0"
            placeholder="Search"
            onChange={handleSearch}
            aria-label="Search"
          />
        </div>

        <Link
          type="button"
          className="ml-auto btn btn-round mr-1 shadow"
          to={"/devices/set"}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Link>
      </div>

      <div className="card shadow-none mt-4 box-shadow">
        <DataTable
          contextActions={contextAction}
          title={"Devices"}
          customStyles={TableStyle1}
          columns={columns}
          data={searchQuery.length > 1 ? searchResult : devices}
          pagination
          paginationPerPage={5}
          selectableRows
          onSelectedRowsChange={handleSelectedRow}
          clearSelectedRows={toggleSelection}
        />
      </div>
    </>
  );
};

export default Devices;
