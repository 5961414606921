import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import axiosInstance from "./axiosInstance";
import { useParams } from "react-router-dom";
import { hasFormSubmit } from "@testing-library/user-event/dist/utils";
import DataTable from "react-data-table-component";
import TableStyle1 from "./TableStyle";
import jsPDF from "jspdf";
import "jspdf-autotable";
import exportToExcel from "./xlsx";

const Reports = () => {
  const [requestPending, setRequestPending] = useState(false);
  const [date1, setDate1] = useState(moment().format("YYYY-MM-DD"));
  const [date2, setDate2] = useState(moment().format("YYYY-MM-DD"));
  const [logs, setLogs] = useState([]);
  const [groups, setGroups] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [designation, setDesignations] = useState([]);
  const [formData, setFormData] = useState({
    designation: "",
    group: "",
    weekend: false,
    shift: "",
  });

  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const result = logs.filter((row) => {
      return (
        row.Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.EnrollNumber.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setSearchResult(result);
  }, [searchQuery]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setRequestPending(true);

    axiosInstance
      .get("/reports/fetch", {
        params: {
          designation: formData.designation,
          group: formData.group,
          shift: formData.shift,
          weekend: formData.weekend,
          date1: date1,
          date2: date2,
        },
      })
      .then((response) => {
        setRequestPending(false);
        if (response.data.status === "success") {
          setLogs(
            response.data.attendanceLogs.sort(
              (a, b) =>
                a.TotalClockInVarianceMinutes - b.TotalClockInVarianceMinutes
            )
          );
        }
      })
      .catch((error) => {
        setRequestPending(false);
      });
  };

  const columns = [
    { name: "Ac. No", cell: (row) => <span>{row.EnrollNumber}</span> },
    {
      name: "Name",
      cell: (row) => <span className="row-title">{row.Name}</span>,
    },
    {
      name: "CIN Variance(mins)",
      cell: (row) => <span>{row.TotalClockInVarianceMinutes}</span>,
      sortable: true,
    },
    {
      name: "COUT Variance (mins)",
      cell: (row) => <span>{row.TotalClockOutVarianceMinutes}</span>,
      sortable: true,
    },

    { name: "Overtime", cell: (row) => <span>{row.Overtime}</span> },
    { name: "Absent", cell: (row) => <span>{row.AbsentDays}</span> },

    { name: "No. Clockin", cell: (row) => <span>{row.ClockInCount} </span> },
    { name: "No. Clockout", cell: (row) => <span>{row.ClockOutCount}</span> },
    {
      name: "Attendance Rate",
      cell: (row) => <span>{row.AttendanceRate}%</span>,
    },
    {
      name: "Days",
      cell: (row) => <span>{row.NumberOfDays}</span>,
    },
  ];

  // Function to export PDF
  const exportPDF = () => {
    const doc = new jsPDF("landscape");

    doc.text(
      `Cumulative Attendance Report ${formData.designation}; ${date1}-${date2}`,
      10,
      10
    );

    doc.autoTable({
      headStyles: { fillColor: [71, 160, 72] },
      head: [
        [
          "Ac. No",
          "Name",
          "Clockin variance",
          "Clockout variance",
          "Overtime",
          "Absent",
          "Clockin count",
          "Clockout count",
          "Attendance Rate %",
          "Days",
        ],
      ],
      body: logs.map((row) => [
        row.EnrollNumber,
        row.Name,
        row.TotalClockInVarianceMinutes,
        row.TotalClockOutVarianceMinutes,
        row.Overtime,
        row.AbsentDays,
        row.ClockInCount,
        row.ClockOutCount,
        row.AttendanceRate,
        row.NumberOfDays,
      ]),
    });

    doc.save(
      `Cumulative attendance report ${formData.designation}-${date1}-${date2}.pdf`
    );
  };

  const exportExcel = () => {
    const title = `Cumulative attendance report ${formData.designation}-${date1}-${date2}`;
    exportToExcel(logs, title, title);
  };

  useEffect(() => {
    axiosInstance.get("/groups/get", { timeout: 10000 }).then((response) => {
      setGroups(response.data);
    });

    axiosInstance
      .get("/shifts/get", { timeout: 10000 })
      .then((response) => {
        setShifts(response.data);
      })
      .catch((error) => {});

    axiosInstance
      .get("/devices/get", { timeout: 5000 })
      .then((response) => {
        setDesignations(response.data);
      })
      .catch((error) => {});
  }, []);
  return (
    <>
      <div className="card shadow-none d-flex flex-row align-items-center">
        <div className="input-group border-0 mr-2">
          <div className="input-group-prepend border-0">
            <span className="input-group-text border-0">
              <i className="material-icons">search</i>
            </span>
          </div>
          <input
            type="text"
            className="form-control border-0"
            placeholder="Search"
            aria-label="Search"
            onChange={handleSearch}
          />
        </div>

        <div className="dropdown">
          <button
            type="button"
            className="ml-auto btn btn-round mr-1 shadow"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="material-icons">download</span>
          </button>

          <div className="dropdown-menu">
            <button className="dropdown-item" onClick={exportExcel}>
              Export excel
            </button>
            <button className="dropdown-item" onClick={exportPDF}>
              Export pdf
            </button>
          </div>
        </div>
      </div>

      <h6 className="mt-3">Select date range</h6>
      <div className=" p-2 card shadow-none">
        <form
          className="d-flex flex-column flex-sm-row align-lg-items-center "
          onSubmit={formSubmit}
        >
          <div className="d-flex align-items-center">
            <label className="">Start: </label>

            <DatePicker
              showIcon
              selected={date1}
              onChange={(date) => setDate1(formatDate(date))}
              dateFormat="yyyy/MM/dd"
              placeholderText="Select date"
              name="date1"
              className="form-control"
              icon={
                <FontAwesomeIcon className="icon-light" icon={faCalendar} />
              }
            />
          </div>
          <div className="d-flex align-items-center mx-2">
            <label className="">End: </label>

            <DatePicker
              showIcon
              selected={date2}
              onChange={(date) => setDate2(formatDate(date))}
              dateFormat="yyyy/MM/dd"
              placeholderText="Select date"
              name="date1"
              className="form-control"
              icon={
                <FontAwesomeIcon className="icon-light" icon={faCalendar} />
              }
            />
          </div>
          <div className="d-flex align-items-center">
            <label>Group:</label>
            <select
              name="group"
              className="form-control"
              onChange={handleInputChange}
            >
              <option value={""}>Select</option>
              {groups.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex align-items-center ml-2">
            <label>Shift:</label>
            <select
              name="shift"
              className="form-control"
              onChange={handleInputChange}
            >
              <option value={""}>Select</option>
              {shifts.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex align-items-center ml-1">
            <label>Weekend:</label>
            <select
              name="weekend"
              className="form-control"
              onChange={handleInputChange}
            >
              <option value={""}>Select</option>

              <option value={false}>Yes</option>
              <option value={true}>No</option>
            </select>
          </div>
          <div className="d-flex align-items-center ml-1">
            <label>Designation:</label>
            <select
              name="designation"
              className="form-control"
              onChange={handleInputChange}
            >
              <option value={""}>Select</option>
              {designation.map((item, index) => (
                <option key={index} value={item.designation}>
                  {item.designation}
                </option>
              ))}
            </select>
          </div>

          {requestPending && (
            <div className="mla-auto">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}

          {!requestPending && (
            <button className="btn btn-round ml-auto" type="submit">
              <span className="material-icons">search</span>
            </button>
          )}
        </form>
      </div>

      <div className="card shadow-none mt-4 box-shadow">
        <DataTable
          title={`Cumulative Attendance Report`}
          customStyles={TableStyle1}
          columns={columns}
          data={searchQuery.length > 1 ? searchResult : logs}
          pagination
          paginationPerPage={5}
          sortable
        />
      </div>
    </>
  );
};

export default Reports;
