import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "./axiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faCogs,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import TableStyle1 from "./TableStyle";
import { Modal } from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { start } from "nprogress";

const Shifts = () => {
  const navigate = useNavigate();
  const sweetAlert = withReactContent(Swal);

  const [shifts, setShifts] = useState([]);

  const [rowSelected, setRowSelected] = useState([]);
  const [toggleSelection, setToggleSelection] = useState(false);

  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const result = shifts.filter((row) => {
      return row.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setSearchResult(result);
  }, [searchQuery]);

  const fetchShifts = () => {
    axiosInstance
      .get("/shifts/get", { timeout: 10000 })
      .then((response) => {
        setShifts(response.data);
      })
      .catch((error) => {});
  };

  const handleSelectedRow = ({ selectedRows }) => {
    setRowSelected(selectedRows);
  };

  const deleteRecords = () => {
    axiosInstance
      .post(
        "/shifts/delete",
        rowSelected.map((item) => item.id),
        { timeout: 10000 }
      )
      .then((response) => {
        setToggleSelection(!toggleSelection);
        fetchShifts();
      })
      .catch((error) => {});
  };

  const updateShift = (shift) => {
    const data = {
      id: shift.id,
      Name: shift.name,
      start: shift.start,
      startVariance: shift.startvariance,
      end: shift.end,
      endVariance: shift.endvariance,
    };

    const encoded = btoa(JSON.stringify(data));

    navigate(`/shifts/set/${encoded}`);
  };

  const contextAction = (
    <button className="btn" type="button" onClick={() => deleteRecords()}>
      <FontAwesomeIcon icon={faTrash} />
    </button>
  );

  const columns = [
    {
      name: "Name",
      cell: (row) => <span className="row-title">{row.name}</span>,
    },
    { name: "start", cell: (row) => <span>{row.start}</span> },

    {
      name: "ClockIn Variance",
      cell: (row) => <span>{row.startvariance} minutes</span>,
    },

    { name: "end", cell: (row) => <span>{row.end} </span> },

    {
      name: "ClockOut Variance",
      cell: (row) => <span>{row.endvariance} minutes</span>,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="dropdown">
          <button
            className="btn"
            role="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <FontAwesomeIcon className="icon-light" icon={faCogs} />
          </button>
          <div className="dropdown-menu dropdown-menu-right ">
            <button class="dropdown-item" onClick={() => updateShift(row)}>
              Update shift
            </button>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchShifts();
  }, []);
  return (
    <>
      <div className="card shadow-none d-flex flex-row align-items-center">
        <div className="input-group border-0 mr-2">
          <div className="input-group-prepend border-0">
            <span className="input-group-text border-0">
              <i className="material-icons">search</i>
            </span>
          </div>
          <input
            type="text"
            className="form-control border-0"
            placeholder="Search"
            aria-label="Search"
            onChange={handleSearch}
          />
        </div>

        <Link
          type="button"
          className="ml-auto btn btn-round mr-1 shadow"
          to={"/shifts/set/"}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Link>
      </div>

      <div className="card shadow-none mt-4 box-shadow">
        <DataTable
          title={`Shifts`}
          columns={columns}
          data={searchQuery.length > 1 ? searchResult : shifts}
          customStyles={TableStyle1}
          selectableRows
          pagination
          paginationPerPage={5}
          onSelectedRowsChange={handleSelectedRow}
          clearSelectedRows={toggleSelection}
          contextActions={contextAction}
        />
      </div>
    </>
  );
};

export default Shifts;
