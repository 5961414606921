import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import TableStyle1 from "../TableStyle";
import axiosInstance from "../axiosInstance";

const ManageUsers = () => {
  const [rowSelected, setRowSelected] = useState([]);
  const [toggleSelection, setToggleSelection] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);

  const handleSelectedRow = ({ selectedRows }) => {
    setRowSelected(selectedRows);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const deleteUsers = () => {
    axiosInstance
      .post(
        "/auth/delete",
        rowSelected.map((item) => item.id),
        { timeout: 10000 }
      )
      .then((response) => {
        setToggleSelection(!toggleSelection);
        fetchUsers();
      })
      .catch((error) => {});
  };

  const contextAction = (
    <button className="btn" type="button" onClick={() => deleteUsers()}>
      <FontAwesomeIcon icon={faTrash} />
    </button>
  );

  const columns = [
    {
      name: "Name",
      cell: (row) => <span className="row-title">{row.username}</span>,
    },
  ];

  const fetchUsers = () => {
    axiosInstance
      .get("auth/get", { timeout: 10000 })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const result = users.filter((row) => {
      return row.username.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setSearchResult(result);
  }, [searchQuery]);

  useEffect(() => {
    fetchUsers();
  }, []);
  return (
    <>
      <div className="card shadow-none d-flex flex-row align-items-center">
        <div className="input-group border-0 mr-2">
          <div className="input-group-prepend border-0">
            <span className="input-group-text border-0">
              <i className="material-icons">search</i>
            </span>
          </div>
          <input
            type="text"
            className="form-control border-0"
            placeholder="Search"
            aria-label="Search"
            onChange={handleSearch}
          />
        </div>

        <Link
          type="button"
          className="ml-auto btn btn-round mr-1 shadow"
          to={"/auth/users/set"}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Link>
      </div>

      <div className="card shadow-none mt-4 box-shadow">
        <DataTable
          contextActions={contextAction}
          title={`Application users`}
          customStyles={TableStyle1}
          columns={columns}
          data={searchQuery.length > 1 ? searchResult : users}
          pagination
          paginationPerPage={5}
          selectableRows
          onSelectedRowsChange={handleSelectedRow}
          clearSelectedRows={toggleSelection}
        />
      </div>
    </>
  );
};

export default ManageUsers;
